<template>
  <header>
    <nav v-bind:class="{mobileMenu: !doesMenuFit, desktopMenu: doesMenuFit, mobileMenuOpen, mobileMenuClosed: !mobileMenuOpen, evaluatingMenuSize}">
      <a class="brand" :href="homeURL" :target="logoTarget" :title="title">
        {{siteName}}
      </a>
      <ul class="mobileColapsedButtons">
        <li><basket class="basket" v-on:click="closeMobileMenu()"></basket></li>
        <dropdown class="languagesSelector languageSelectorMobile" v-if="allLanguages.length > 1"
        :text="language">
          <li v-for="(language, index) in allLanguages" :key="language">
            <a class="languageBtn" :href="'/'+language" v-on:click.prevent="changeLanguage(language)">
             {{allFullLanguages[index]}}
            </a>
          </li>
        </dropdown>
        <li><button class="toggler" v-on:click="toggleMobileMenu()">
            <Icon icon="bars" class="mobileMenuIcon" width="16"></Icon>
          </button></li>
      </ul>
      <ul class="menu">
        <li v-if="hasTickets">
          <router-link class="tickets" v-on:click="closeMobileMenu()" :to="{name: 'tickets', params: {lang: language}}">
            {{getNavName('NAV_TICKETS')}}
          </router-link>
        </li>
        <li v-if="hasGuidedTours">
          <router-link class="guidedTours" v-on:click="closeMobileMenu()" :to="{name: 'guidedTours', params: {lang: language}}">
            {{getNavName('NAV_GUIDED_TOURS')}}
          </router-link>
        </li>
        <li v-if="hasMerchandise">
          <router-link class="merchandise" v-bind:class="{'router-link-active': isProduct}" v-on:click="closeMobileMenu()" :to="{name: 'merchandise', params: {lang: language}}">
            {{getNavName('NAV_MERCHANDISE')}}
          </router-link>
        </li>
        <li v-if="hasPasses">
          <router-link class="pass" v-on:click="closeMobileMenu()" :to="{name: 'passes', params: {lang: language}}">
            {{getNavName('NAV_MEMCARD')}}
          </router-link>
        </li>
        <li v-if="hasVouchers">
          <router-link class="vouchers" v-on:click="closeMobileMenu()" :to="{name: 'voucherList', params: {lang: language}}">
            {{getNavName('NAV_VOUCHERS')}}
          </router-link>
        </li>
        <dropdown class="languagesSelector" v-if="allLanguages.length > 1"
        :text="translate('LANG_FULL')">
        <li v-for="(language, index) in allLanguages" :key="language">
          <a class="languageBtn" :href="'/'+language" v-on:click.prevent="changeLanguage(language)">
            {{allFullLanguages[index]}}
          </a>
        </li>
        </dropdown>
        <li class="basketItem"><basket class="basket" v-on:click="closeMobileMenu()"></basket></li>
        <li class="login" v-if="(!isUserLoggedIn || isGuest) && hasUserRegistration">
          <router-link class="loginLink" v-on:click="closeMobileMenu()" :to="{name: 'login', params: {lang: language}}">
            {{$t('NAV_LOGIN')}}
          </router-link>
        </li>
        <li class="register registerLink"
          v-if="(!isUserLoggedIn || isGuest) && hasUserRegistration" >
          <router-link :to="{name:'register', params: {lang: language}}" v-on:click="closeMobileMenu()">
            {{$t('NAV_REGISTER')}}
          </router-link>
        </li>
        <dropdown :text="customerName"
         v-if="isUserLoggedIn && !isGuest" class="userMenu">
        <li><router-link :to="{name: 'settings', params: {lang: language}}" v-on:click="closeMobileMenu()">
            {{$t('NAV_SETTINGS')}}
          </router-link></li>
          <li><router-link :to="{name: 'logout'}" v-on:click="closeMobileMenu()">{{$t('NAV_LOGOUT')}}</router-link></li>
        </dropdown>
      </ul>
    </nav>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import basket from '../Checkout/Basket.vue';
import dropdown from '../Dropdown/Dropdown.vue';
import Icon from '../Icon.vue';

let elementSizesTimeout;
let lastEvaluation = 0;

export default {
  name: 'NavbarNew',
  components: { basket, dropdown, Icon },
  data() {
    return {
      navWidth: null,
      navHeight: null,
      brandWidth: null,
      brandHeight: null,
      menuWidth: null,
      menuHeight: null,
      doesMenuFit: true,
      mobileMenuOpen: false,
      evaluatingMenuSize: false,
      windowWidth: null,
    };
  },
  computed: {
    ...mapState({
      hasGuidedTours: (state) => state.app.hasGuidedTours,
      hasMerchandise: (state) => state.app.hasMerchandise,
      allLanguages: (state) => state.app.languages,
      allFullLanguages: (state) => state.app.languagesFull,
      hasTickets: (state) => state.app.hasTickets,
      hasVouchers: (state) => state.app.hasVouchers,
      isUserLoggedIn: (state) => state.user.isLoggedIn,
      hasUserRegistration: (state) => state.app.hasUserRegistration,
      language: (state) => state.language,
      siteName: (state) => state.app.siteName,
      isGuest: (state) => state.customer.data.isGuest,
      siteNameTranslations: (state) => state.app.siteNameTranslations,
      navTranslations: (state) => state.app.navTranslations,
      logoLinksToMainSite: (state) => state.app.logoLinksToMainSite,
      museumPerLanguageUrls: (state) => state.app.museumPerLanguageUrls,
      museumMainUrl: (state) => state.app.museumMainUrl,
      hasPasses: state => state.app.hasMemCard,

    }),

    isProduct() {
      if (this.$route.name === 'product') {
        return true;
      }
      return false;
    },
    customerName() {
      if (typeof this.$store.state.customer.data.fname !== 'undefined'
        && typeof this.$store.state.customer.data.lname !== 'undefined') {
        return `${this.$store.state.customer.data.fname} ${this.$store.state.customer.data.lname}`;
      }
      return '';
    },
    homeURL() {
      let url = `/${this.language}`;
      if (this.logoLinksToMainSite === true) {
        url = this.museumMainUrl;
        if (typeof this.museumPerLanguageUrls[this.language] !== 'undefined') {
          url = this.museumPerLanguageUrls[this.language];
        }
      }
      return url;
    },
    logoTarget() {
      if (this.logoLinksToMainSite === true) {
        return '_blank';
      }
      return '';
    },
    title() {
      let title = this.siteName;
      if (typeof this.siteNameTranslations[this.language] !== 'undefined') {
        title = this.siteNameTranslations[this.language];
      }
      return title;
    },
  },
  methods: {
    translate(term) {
      return this.$t(term);
    },
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
    closeMobileMenu() {
      this.mobileMenuOpen = false;
    },
    getNavName(type) {
      if (typeof this.navTranslations !== 'undefined') {
        if (typeof this.navTranslations[type] !== 'undefined') {
          if (typeof this.navTranslations[type][this.language] !== 'undefined' && this.navTranslations[type][this.language] !== '') {
            return this.navTranslations[type][this.language];
          }
        }
      }
      return this.$t(type);
    },
    changeLanguage(lang) {
      const path = this.$route.fullPath.split('/')
      path[1] = lang
      this.$router.push(path.join("/")).then(() => {
        window.location.reload()
      });
    },
    calculateElementSizes() {
      this.evaluatingMenuSize = true;
      const currentTime = Date.now();
      if (currentTime - lastEvaluation < 500) {
        if (elementSizesTimeout) {
          clearTimeout(elementSizesTimeout);
        }
        elementSizesTimeout = window.setTimeout(this.calculateElementSizes, 500);
      } else {
        if (this.mobileMenuOpen) {
          elementSizesTimeout = window.setTimeout(this.calculateElementSizes, 500);
          return;
        }
        this.doesMenuFit = true;
        this.mobileMenuOpen = false;
        const nav = this.$el.querySelector('nav');
        const brand = this.$el.querySelector('.brand');
        const menu = this.$el.querySelector('.menu');
        this.navWidth = nav.offsetWidth;
        this.navHeight = nav.offsetHeight;
        this.brandWidth = brand.offsetWidth;
        this.brandHeight = brand.offsetHeight;
        this.menuWidth = menu.offsetWidth;
        this.menuHeight = menu.offsetHeight;
        // if we have a fixed logo it should not be taken into account for calculations
        if (getComputedStyle(brand).position === 'fixed'
          || getComputedStyle(brand).position === 'absolute') {
          this.brandWidth = 0;
        }
        if (this.menuWidth === 0) {
          if (elementSizesTimeout) {
            clearTimeout(elementSizesTimeout);
          }
          elementSizesTimeout = window.setTimeout(this.calculateElementSizes, 500);
          return;
        }
        if (this.navWidth - this.brandWidth - this.menuWidth >= 0) {
          this.doesMenuFit = true;
        } else {
          this.doesMenuFit = false;
          clearTimeout(elementSizesTimeout);
        }
        this.evaluatingMenuSize = false;
      }
      lastEvaluation = currentTime;
    },
    orientationChange() {
      this.calculateElementSizes();
    },
    resize() {
      // mobile safari triggers width changes without width actually changing
      if (!this.windowWidth) {
        this.windowWidth = window.innerWidth;
        this.calculateElementSizes();
      } else if (this.windowWidth !== window.innerWidth) {
        this.windowWidth = window.innerWidth;
        this.calculateElementSizes();
      }
    },
  },
  watch: {
    language(newV) {
      this.$i18n.locale = newV
    }
  },
  mounted() {
    this.calculateElementSizes();
    window.addEventListener('orientationchange', this.orientationChange);
    window.addEventListener('resize', this.resize);
  },
  unmounted() {
    window.removeEventListener('orientationchange', this.calculateElementSizes);
    window.removeEventListener('resize', this.calculateElementSizes);
  },
};

</script>

<style lang="scss" scoped>

$logo-mobile-url: "" !default;
header {
  background: $navbar-default-bg;
}

nav {
  margin-left: 2.5%;
  margin-right: 2.5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  min-height: $navbar-height;
  font-family: $navbar-font-family;
  transition: opacity 0.5s;
}
nav.mobileMenuOpen {
  position: fixed;
  height: 100vh;
  overflow-y: auto;
}

.evaluatingMenuSize {
  flex-wrap: nowrap;
}

.brand {
  margin-left: $sp;
}

header {
  border-bottom: 1px solid $navbar-default-border;
}

header, nav {
  background: $navbar-default-bg;
}

.brand {
  /*color: #FC3A51;*/
  background: $logo-url no-repeat;
  background-size: contain;
  text-indent: -2000%;
  margin-top: $sp;
  margin-bottom: $sp;
  background-position: left center;
  min-width: $logo-min-width;
  min-height: $logo-min-height;
}

.mobileMenu .brand {
  position: absolute;
  top: 0;
  left: $sp;
}

.menu {
  list-style: none;
  padding-left: 0;
  padding-right: $sp;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  opacity: 0;
  margin-top: $sp;
  margin-bottom: $sp;
  gap: 0 1.5*$sp;
  a {
    text-decoration: none;
    font-size: $navbar-default-font-size;
    font-weight: $navbar-default-font-weight;
    color: $navbar-default-link-color;
    padding-left: $sp;
    padding-right: $sp;
    white-space: nowrap;
    @media screen and (max-width: 992px) {
      font-size: $navbar-default-font-size * 0.875;
    }
  }
  a:hover {
    text-decoration: $navbar-default-link-hover-text-decoration;
  }

  a.router-link-active {
    text-decoration: underline;
  }

  .userMenu:deep(a) {
    font-weight: $navbar-default-font-weight;
    font-size: $navbar-default-font-size;
    color: $navbar-default-link-color;
    @media screen and (max-width: 992px) {
      font-size: $navbar-default-font-size * 0.875;
    }
  }
  >li:last-child {
    margin-right: -$sp;
  }
}

.languagesSelector:deep(a) {
  font-weight: $navbar-default-font-weight;
  font-size: $navbar-default-font-size;
  color: $navbar-default-link-color;
  @media screen and (max-width: 992px) {
    font-size: $navbar-default-font-size * 0.875;
  }
}
.languagesSelector:deep(a:hover) {
  text-decoration: $navbar-default-link-hover-text-decoration;
}

.menu:deep(.dropdown-menu) {
    background: lighten($navbar-default-bg, 5%);
}
/*
.menu:deep(.dropdown-toggle) {
      font-size: $navbar-default-font-size * 0.875;
  }
*/

nav:deep(.cartIcon svg .fill), nav:deep(.mobileMenuIcon svg .fill) {
    fill: $navbar-default-link-color !important;
}

.desktopMenu .mobileColapsedButtons {
  display: none;
}
.mobileMenu {
  flex-direction: column;
  flex-wrap: nowrap;
  z-index: $zindex-navbar;
  .menu {
    flex-direction: column;
    justify-content: center;
    opacity: 1;
    margin-top: 0;
    min-width: 100%;
    margin-bottom: 0;
    //min-height: 100vh;
    li {
      margin-top: $sp;
      margin-bottom: $sp;
    }
  }
  .brand {
    margin-left: 0;
  }
}

.languageSelectorMobile:deep(.dropdown-toggle) {
  text-transform: uppercase;
}

.mobileMenuOpen {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding-right: $sp;
  padding-left: $sp;
  .languageSelectorMobile {
    display:none;
  }
  .menu {
    padding-right: 0;
    li:last-child {
      margin-right: 0;
    }
  }
}

.mobileMenu.mobileMenuClosed .menu {
  display: none !important;
}
.desktopMenu .menu {
  opacity: 1;
}
.mobileColapsedButtons {
  align-self: flex-end;
  list-style: none;
  right: $sp;
  margin-left: 2*$sp;
  padding-left: 0;
  text-align: right;
  margin-top: 2 * $sp;
  z-index: 2;
  li {
    display: inline-block;
    margin-left: $sp;
    margin-right: $sp;
  }
  li:last-of-type {
    margin-right: 0;
  }
  .toggler {
    background: transparent;
    border: 0;
    box-shadow: none;
    line-height: 1;
  }
  .desktopMenu .mobileColapsedButtons {
    display: none;
  }

  .mobileMenu {
    flex-direction: column;
    flex-wrap: nowrap;
    z-index: $zindex-navbar;
    .menu {
      flex-direction: column;
      justify-content: center;
      opacity: 1;
      margin-top: 0;
      min-width: 100%;
      margin-bottom: 0;
      //min-height: 100vh;
      li {
        margin-top: $sp;
        margin-bottom: $sp;
      }
    }
  }
  .mobileMenuOpen {
    width: 100%;
    margin-left: 0;
  }
  .mobileMenu.mobileMenuClosed .menu {
    display: none !important;
  }
  @media screen and (max-width: 930px) {
    li a {
      padding-left: 7px !important;
      padding-right: 7px !important;
    }
  }
}
nav .menu:deep(.dropdown) {
  li {
    padding-left: $sp;
    padding-right: $sp;
  }
}

.mobileMenuOpen :deep(.dropdown) {
  width: 100%;
  text-align: center;
  width: calc(100vw - 2 * #{$sp});
  .dropdown-menu {
    text-align: center;
    background: rgba(adjust-hue(invert($navbar-default-bg), 180deg), 0.075) !important;
    //adjust-hue(#d2e1dd, -60deg)
    border: none;
    box-shadow: none;
    position: static;
    float: none;
    a:hover, a:focus {
      background: transparent;
    }
  }
}

</style>
