let lastPrefix = null
let separator = '-';
if (['bo', 'devbo'].includes(import.meta.env.VITE_SITE_FRONT_ID)) {
  separator = '|';
}

export function setPageTitle(prefix) {
  let siteName = import.meta.env.VITE_SITE_NAME;
  if(typeof window.vue.$store.state.app.siteName !== 'undefined') {
    siteName = window.vue.$store.state.app.siteName
  }
  if(typeof window.vue.$store.state.app.siteNameTranslations !== 'undefined' && typeof window.vue.$store.state.language !== 'undefined') {
    if(typeof window.vue.$store.state.app.siteName[window.vue.$store.state.language] !== 'undefined') {
      siteName = window.vue.$store.state.app.siteName[window.vue.$store.state.language]
    }
  }

  let title = '';
  if (prefix === '' || typeof prefix === 'undefined') {
    lastPrefix = null
    title = siteName;
  } else {
    title = `${prefix} ${separator} ${siteName}`;
    lastPrefix = prefix
  }
  //return title;
  document.title = title;
};

export function updateSiteName(siteName) {
  let title = `${siteName}`
  if(lastPrefix != null) {
    title = `${lastPrefix} ${separator} ${siteName}`;
  }
  //document.title = title;
}



export default {setPageTitle, updateSiteName}
