import request from '../request';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    categories: [],
    data: [],
    order: [],
    form: {
      quantity: 1,
      names: [{value: ''}],
      hasCustomAddresses: [{value: false}],
      addresses: [{value: ''}]
    },
  },
  mutations: {
    set(state, payload) {
      const order = Object.keys(payload);
      order.sort(
        (a, b) => parseInt(payload[a].sequence_no, 10) - parseInt(payload[b].sequence_no, 10),
      );
      payload.forEach((p) => {
        if (typeof p.custom_groups === 'undefined') {
          p.custom_groups = []; //eslint-disable-line
        }
      });
      state.data = payload;
      state.order = order;
    },
    setLoading(state, isLoading) {
      Object.assign(state, { isLoading });
    },
    setSingle(state, payload) {
      const newData = [];
      newData.push(payload);
      if (typeof payload.custom_groups === 'undefined') {
        payload.custom_groups = []; //eslint-disable-line
      }
      state.data = newData;
    },
    setCategories(state, categories) {
      const sortedCategories = categories.sort(
        (a, b) => parseInt(a.sequence_no, 10) > parseInt(b.sequence_no, 10),
      );
      const c = [];
      sortedCategories.forEach((sortedCategory) => {
        c.push({
          id: sortedCategory.id,
          name: sortedCategory.name,
          selected: false,
        });
      });
      state.categories = c;
    },
    setQuantity(state, quantity) {
      Object.assign(state.form, { quantity });
      if(quantity !== state.form.names.length) {
        if(quantity > state.form.names.length) {
          while(quantity > state.form.names.length) {
            state.form.names.push({value: ''})
            state.form.hasCustomAddresses.push({value: false})
            state.form.addresses.push({value: ''})
          }
        }else if(quantity < state.form.names.length) {
          while(quantity < state.form.names.length) {
            state.form.names.pop()
            state.form.hasCustomAddresses.pop()
            state.form.addresses.pop()
          }
        }
      }
    },
    setName(state, {index, value}) {
      state.form.names[index] = {value}
    },
    setHasCustomAddress(state, {index, value}) {
      state.form.hasCustomAddresses[index] = {value}
    },
    setAddress(state, {index, value}) {
      state.form.addresses[index] = {value}
    },
    resetForm(state) {
      if (parseInt(state.data[Object.keys(state.data)[0]].stock, 10) <= 0) {
        Object.assign(state.form, { quantity: 0 });
      } else {
        Object.assign(state.form, { quantity: 1 });
      }
    },
    setCategoryFilter(state, payload) {
      const categoryId = Object.keys(payload)[0];
      const val = payload[categoryId];
      state.categories.forEach((category, i) => {
        state.categories[i].selected = false;
      });

      state.categories.find((c, i) => {
        if (parseInt(c.id, 10) === parseInt(categoryId, 10)) {
          state.categories[i].selected = val;
          return true;
        }
        return false;
      });
    },
    resetAllCategories(state) {
      Object.keys(state.categories).forEach((k) => {
        state.categories[k].selected = false;
      });
    },
  },
  actions: {
    resetForm(context) {
      context.commit('resetForm');
    },
    get(context, data) {
      return new Promise((resolve) => {
        context.commit('setLoading', true);
        const { app } = context.rootState;
        request.make({
          type: 'GET',
          url: `${app.API_URL}${context.rootState.language}/${app.endpoints.MERCHANDISE}`,
          data,
        }, (err, res) => {
            context.commit('setLoading', false);
            if (res.status === 200) {
              if (typeof res.body !== 'undefined') {
                context.commit('set', res.body.data);
                resolve();
              }
            }
          });
      });
    },
    getSingle(context, id) {
      return new Promise((resolve) => {
        context.commit('setLoading', true);
        const { app } = context.rootState;
        const data = { article: id };
        request.make({
          type: 'GET',
          url: `${app.API_URL}${context.rootState.language}/${app.endpoints.MERCHANDISE_SINGLE}`,
          data,
        }, (err, res) => {
            context.commit('setLoading', false);
            if (res.status === 200) {
              if (typeof res.body !== 'undefined') {
                context.commit('setSingle', res.body.data);
              }
            }
            resolve();
          });
      });
    },
    getCategories(context, productType) {
      return new Promise((resolve) => {
        const { app } = context.rootState;
        let params = ""
        if(productType === "annualPasses") {
         params = "?product_kind=13"
        }
        request.make({
          type: 'GET',
          url: `${app.API_URL}${context.rootState.language}/${app.endpoints.MERCHANDISE_CATEGORY_LIST}${params}`,
        }, (err, res) => {
            if (res.status === 200) {
              if (typeof res.body !== 'undefined') {
                if (typeof res.body.data !== 'undefined') {
                  if (typeof res.body.data.category !== 'undefined') {
                    context.commit('setCategories', res.body.data.category);
                    resolve();
                  }
                }
              }
            }
          });
      });
    },
    setCategoryFilter(context, payload) {
      context.commit('setCategoryFilter', payload);
    },
    setName(context, {index, value}) {
      context.commit('setName', {index, value})
    },
    setHasCustomAddress(context, {index, value}) {
      context.commit('setHasCustomAddress', {index, value})
    },
    setAddress(context, {index, value}) {
      context.commit('setAddress', {index, value})
    },
    setQuantity(context, quantity) {
      context.commit('setQuantity', quantity);
    },
    addToCart(context, id) {
      return new Promise((resolve) => {
        const product = context.state.data.find((p) => parseInt(p.id, 10) === id);
        const item = {};
        item.name = product.name;
        item.type = 'merchandise';
        if(product.product_kind_ref === "13") {
          item.type = 'annualPass'
          item.items = []
          context.state.form.names.forEach((name, index) => {
            const cardData = {name: name.value}
            if(context.state.form.hasCustomAddresses[index].value) {
              cardData.address = context.state.form.addresses[index].value
            }
            item.items.push(cardData)
          })
        }
        item.count = context.state.form.quantity;
        item.price = parseInt(product.price, 10);
        item.vat_value = parseFloat(product.vat_value);
        item.totalPrice = product.price * context.state.form.quantity;
        item.id = parseInt(product.id, 10);
        context.dispatch('cart/add', item, { root: true });
        context.dispatch('resetForm');
        resolve();
      });
    },
    resetAllCategories(context) {
      context.commit('resetAllCategories');
    },
  },
  getters: {
    filteredList: (state) => {
      const list = {};
      const enabledCategoryIds = [];
      state.categories.filter((c) => {
        if (c.selected) {
          enabledCategoryIds.push(c.id);
          return true;
        }
        return false;
      });

      if (state.categories.length > 0 && enabledCategoryIds.length > 0) {
        Object.keys(state.data).forEach((key) => {
          let shouldAdd = false;
          const productCategoryIds = [];
          state.data[key].custom_groups.forEach((cg) => {
            productCategoryIds.push(cg.id);
          });

          productCategoryIds.find((pCId) => {
            if (enabledCategoryIds.includes(pCId)) {
              shouldAdd = true;
              return true;
            }
            return false;
          });

          if (shouldAdd) {
            list[key] = state.data[key];
          }
        });
        return list;
      }
      return state.data;
    },
    noCategoriesSelected: (state) => {
      const selected = state.categories
      .find((c) => c.selected);
      if (typeof selected === 'undefined') {
        return true;
      }
      return false;
    },
  },
};
